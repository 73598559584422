import Assert from "./asserts"
import { DATA_SOURCES, MetadataField, ReportField, ReportFillingType, SrcFieldValue } from "../constnats/reportConstants"
import Utils from "./utils"
import ObjCheck from "./objCheck"
import { DescriptorProps } from "../constnats/descriptor"
import DescriptorType from "./descriptor/descriptorType"

class ReportUtils {

    /**
     * @param {object} report Original representation of the report, not null
     * @returns not-null
     */
    static flattenDynamicFields(report) {
        const flatReport = Object.assign({}, report)
        const dynamicFields = report[ReportField.DYNAMIC_FIELDS]
        if (dynamicFields !== undefined) {
            for (const dynamicField of Object.keys(dynamicFields)) {
                flatReport[dynamicField] = dynamicFields[dynamicField]
            }
            delete flatReport[ReportField.DYNAMIC_FIELDS]
        }

        return flatReport
    }

    /**
     * @param {{}} report. Report as persisted in the database. Required 
     * @param {{}} descriptors As stored in the database. Required 
     * @returns not-null
     */
    static normalizeReport(report, descriptors) {

        const fsMetadata = ReportUtils.getFieldsMetadata(report)
        for (const descriptor of descriptors) {
            const descriptorId = descriptor.id
            fsMetadata[descriptorId] = ReportUtils
                .getNormalizedFieldMetadata(fsMetadata[descriptorId],
                    descriptor[DescriptorProps.INPUT_SCALE])

            const subFields = descriptor[DescriptorProps.SUBFIELDS]
            if (subFields?.length > 0) {
                ReportUtils.normalizeReport(report, subFields)
            }
        }

        return report
    }

    static getDefaultMetadata(defaultInputScale) {
        return {
            [MetadataField.NOTES]: "", [MetadataField.REPORTED_AS_LABEL]: "",
            [MetadataField.CORRECTION]: "0", [MetadataField.MANUALLY_CALCED]: false,
            [MetadataField.INPUT_SCALE]: defaultInputScale,
            [MetadataField.EXTENDED_SEARCH]: null,
            [MetadataField.SRC]: null, [MetadataField.CALCS_DISABLED]: null
        }

    }

    /**
     * @param {{}} fieldMetadata Field metadata, required
     * @param {} defaultInputScale  required
     */
    static getNormalizedFieldMetadata(fieldMetadata, defaultInputScale) {
        return Object.assign(ReportUtils.getDefaultMetadata(defaultInputScale), fieldMetadata)
    }

    /**
     * @param {CellEntity[]} entities Collection of column CellEntity-s, not null
     * @param {object} report The report in original format to
     * which the entities will be later applied. not null
     * @returns not null
     */
    static cellEntitiesToReport(entities, report) {
        const reportRes = { [ReportField.METADATA]: { fieldSpecific: {} } }
        reportRes[ReportField.DYNAMIC_FIELDS] = {}
        for (const entity of entities) {
            const descriptorId = entity.descriptorId()
            if (entity.dynamicField === true) {
                reportRes[ReportField.DYNAMIC_FIELDS][descriptorId] = entity.getData()
            } else {
                reportRes[descriptorId] = entity.getData()
            }

            reportRes[ReportField.METADATA].fieldSpecific[descriptorId] = entity.getMetadata()
        }

        for (const rootField of Object.keys(report)) {
            if (reportRes.hasOwnProperty(rootField) !== true) {
                reportRes[rootField] = report[rootField]
            }
        }

        return reportRes
    }

    /**
     * @param {string} reportType not null
     * @param {boolean} isCalculatedReport 
     * @returns not null
     */
    static columnLabelProps(reportType, isCalculatedReport) {
        let props = null
        if (isCalculatedReport) {
            props = { label: reportType, color: "blue" }
        } else if (reportType === ReportFillingType.LATEST_FILLING) {
            props = { label: reportType, color: "yellow" }
        } else if (reportType === ReportFillingType.EXPECTATION) {
            props = { label: reportType, color: "green" }
        } else if (reportType === ReportFillingType.ORIGINAL_FILLING) {
            props = { label: reportType, color: "grey" }
        }

        return props
    }


    /**
     * @param {{}} report Required 
     * @returns not null
     */
    static getUniqueKeyForCalcedReport(report) {
        return report[ReportField.YEAR] + report[ReportField.PERIOD] + report[ReportField.REPORT_TYPE] + report[ReportField.TYPE]
    }

    /**
     * 
     * @param {Object} report  Required
     * @param {String} descriptorId Required
     * @returns field metadata or undefined
     */
    static getFieldMetadata(report, descriptorId) {
        return report[ReportField.METADATA].fieldSpecific[descriptorId]
    }

    /**
     * @param {{}} report Required 
     * @param {string} descriptorId Required 
     * @returns {boolean} True if field has p2p calculated result in the 
     * FieldMetadata and it is different than undefined, otherwise false
     */
    static _fieldHasP2pCalcRes(report, descriptorId) {
        return !ObjCheck.isNullOrUndefined(ReportUtils.getFieldMetadata(report, descriptorId)?.[MetadataField.P2P_CALC_RES])
    }

    /**
     * @param {{}} report Required 
     * @param {string} descriptorId Required 
     * @param {boolean} isDynamic Optional, true by default
     * @returns {string | null }  String if the value is found, otherwise null
     */
    static getFieldValueOrP2PCalc(report, descriptorId, isDynamic = true) {
        const fields = isDynamic ? report[ReportField.DYNAMIC_FIELDS] : report
        const fieldValue = fields[descriptorId]
        let value = null

        if (ObjCheck.isNullUndefinedEmptyOrDash(fieldValue)) {
            if (this._fieldHasP2pCalcRes(report, descriptorId)) {
                value = ReportUtils.getFieldMetadata(report, descriptorId)[MetadataField.P2P_CALC_RES]
            }
        } else {
            value = fieldValue
        }

        return value
    }

    /**
     * @param {{}} report Required
     * @param {string} descriptorId Required
     * @param {boolean} isDynamic Optional, true by default
     * @returns {boolean} True if report value is found
     */
    static fieldHasValueOrP2PCalc(report, descriptorId, isDynamic = true) {
        const fields = isDynamic ? report[ReportField.DYNAMIC_FIELDS] : report
        return (!ObjCheck.isNullUndefinedEmptyOrDash(fields[descriptorId])
            || this._fieldHasP2pCalcRes(report, descriptorId))
    }

    static getFieldMetadataOrDefault(report, descriptorId) {
        const md = ReportUtils.getFieldMetadata(report, descriptorId)
        return md === undefined ? ReportUtils.getDefaultMetadata(-2) : md
    }


    static getFieldsMetadata(report) {
        return report[ReportField.METADATA].fieldSpecific
    }

    static getMetadataProp(report, prop) {
        return report[ReportField.METADATA][prop]
    }

    static setMetadataProp(report, prop, val) {
        report[ReportField.METADATA][prop] = val
    }

    /**
     * @param {String} id Required
     *
     * @returns not-null empty replate for reports that has all requred props
     * preset with empty values. The only non empty value is the report is.
     */
    static createEmptyReport(id) {
        return {
            id,
            [ReportField.DYNAMIC_FIELDS]: {},
            [ReportField.METADATA]: { fieldSpecific: {} },
            [DescriptorProps.INPUT_SCALE]: 0
        }
    }


   /**
    * @param {string|number} expectedData A string or a number that represents a number or percent. Required
    * @param {string|number} actualData A string or a number that represents a number or percent. Required
    * @param {Object} descriptor Required
    * @param {number} diffThreshold Required
    * @returns  True if a diff that is above the threshold is detected, otherwise false
    */
    static hasFuzzyDiff(expectedData, actualData, descriptor, diffThreshold) {

        let hasDiffAboveThreshold = false

        try {
            let expectedNormalized = 0
            let actualNormalized = 0
            if (DescriptorType.isNumber(descriptor)) {
                expectedNormalized = expectedData
                actualNormalized = actualData
            } else if (DescriptorType.isPercent(descriptor)) {
                expectedNormalized = expectedData.replace("%", "")
                actualNormalized = actualData.replace("%", "")
            }

            if (ReportUtils._hasDiffAboveThreshold(Utils.toNumber(expectedNormalized, false),
                Utils.toNumber(actualNormalized, false), diffThreshold)) {
                hasDiffAboveThreshold = true
            }

        } catch {
            Assert.fail("Unexpected " + descriptor[DescriptorProps.TYPE] +
                " value A:" + expectedData + ", B:" + actualData)
        }

        return hasDiffAboveThreshold
    }

    /**
     * @param {Object} report Required
     * @returns true if the report is calculated, otherwise false
     */
    static isCalced(report) {
        return report[ReportField.SRC] === SrcFieldValue.CALCULATED
    }


    static _getDiffDetectionMethod(report, toReport, descriptorId) {
        //TODO Doc about  ReportUtils.getFieldsMetadata(report)[descriptorId]
        if (ReportUtils.isCalced(report) && ReportUtils.isCalced(toReport)) {
            return "Comparison with Original Calced. Version"
        } else if (ReportUtils.isCalced(report) && ReportUtils.getFieldsMetadata(report)[descriptorId]) {
            return ReportUtils.getFieldsMetadata(report)[descriptorId][MetadataField.P2P_CALC] 
        } else {
            return "Comparison with Original Version"
        }
    }

    /**
     * Sets the diff properties of the latest reports based on the original. 
     * NOTES:
     * 1) A data that is presented in the toReport but not in the fromReport is
     * considered as not difference.
     * 2) Mutates the report if it is latest version and the reportsMap contains
     * 
     * @param {Object} fromReport Report that to be considered as a source of truth. Required
     * @param {Object} toReport Report to mark diffs in. Required
     * @param {[]} dynamicFlatDescriptors Required
     * @param {number} diffThreshold Required
     * @param {boolean} diffIgnoreZeroAndDash Required 
     */
    static markDiffs(fromReport, toReport, dynamicFlatDescriptors,
        diffThreshold, diffIgnoreZeroAndDash) {

        let hasDiff = false
        let hasFuzzyDiff = false
        for (const descriptor of dynamicFlatDescriptors) {
            const descriptorId = descriptor.id

            const fromData = getNormalizedDataValue(fromReport[ReportField.DYNAMIC_FIELDS][descriptorId])
            const toData = getNormalizedDataValue(toReport[ReportField.DYNAMIC_FIELDS][descriptorId])

            if (fromData !== toData) {
                hasDiff = true
                const toFsMData = ReportUtils.getFieldsMetadata(toReport)
                if (toFsMData[descriptorId] === undefined) {
                    toFsMData[descriptorId] = {}
                }

                if (ReportUtils._dashAndZeroDiff(fromData, toData) && diffIgnoreZeroAndDash) {
                    /**
                     * No diff because the diffIgnoreZeroAndDash is enabled and the values are "-"" and "0"
                     */
                } else if (isDash(toData) || isDash(fromData)) {
                    /**
                     * If the fromReport is calculated then do not set diffs
                     * because it is assumed that the calculated value will be 
                     * configured in the P2P_CALC_RES of the field metadata and
                     * the client will see the data as P2P calculated.
                     */
                    if (!isDash(fromData) && !ReportUtils.isCalced(fromReport)) {
                        /**
                         * The new report has filed that is not configured but was in the old one.
                         */
                        hasFuzzyDiff = true
                        ReportUtils.markDiff(ReportUtils._getDiffDetectionMethod(fromReport, toReport, descriptorId), fromData, toFsMData, descriptorId)
                    } else {
                        /**
                         * The  data that is presented in the toReport but not
                         * in the fromReport so it not is considered as a difference.
                         */

                    }
                } else if (ReportUtils.hasFuzzyDiff(fromData, toData, descriptor, diffThreshold)) {
                    ReportUtils.markDiff(ReportUtils._getDiffDetectionMethod(fromReport, toReport, descriptorId), fromData, toFsMData, descriptorId)
                    hasFuzzyDiff = true
                }
            }
        }

        Object.assign(toReport, { hasDiff, hasFuzzyDiff })
    }

    static _dashAndZeroDiff(a, b) {
        return (isDash(a) && b == 0) || (isDash(b) && a == 0)
    }
    static markDiff(detectionMethod, expectation, actualFsMetadata, descriptorId) {
        actualFsMetadata[descriptorId][MetadataField.DATA_DIFF_DETECTION] = detectionMethod
        actualFsMetadata[descriptorId][MetadataField.DATA_DIFF_EXPECTATION] = expectation
    }

    /**
     * Fills in missing values of a report based on calculated report.
     * 
     * NOTE: This function mutates the metadata of the report.
     * 
     * @param {Object} report Required
     * @param {Object} calcedReport Required
     * @param {[]} dynamicDescriptor Required
     */
    static fillInMissingValues(report, calcedReport, dynamicDescriptor) {

        const descriptorId = dynamicDescriptor.id
        const calcedData = calcedReport[ReportField.DYNAMIC_FIELDS][descriptorId]


        /**
         * Calced values equal to - are ignored because they are result of on
         * executed calculations.
         * 
         * Headlines are ignored because thy do not have any values.
         */
        if (calcedData !== "-" && !DescriptorType.isHeadline(dynamicDescriptor)) {
            const data = report[ReportField.DYNAMIC_FIELDS][descriptorId]

            const reportFsMData = ReportUtils.getFieldsMetadata(report)
            if (reportFsMData[descriptorId] === undefined) {
                reportFsMData[descriptorId] = {}
            }

            const p2pCalc = ReportUtils.getFieldMetadata(calcedReport, descriptorId)[MetadataField.P2P_CALC]
            if (ObjCheck.isNullUndefinedEmptyOrDash(data)) {
                /**
                 * Here we handle the case where the data is not presented by a persisted report 
                 * but can be retrieved form a calculated one.
                 *
                 * This will mark the value as p2p calced but will not mark it as a diff.
                 * As a result the cell will be presented as a p2p calculated and without diff.
                 * 
                 * In the current state of the code the diff may be marked at
                 * later point by the Latest and Original Comparison which will
                 * be incorrect. Is it true?
                 */
                ReportUtils._setP2PCalc(reportFsMData, descriptorId, calcedData, p2pCalc)
            }
        }
    }

    static _setP2PCalc(metadata, descriptorId, calcedData, p2pCalc) {
        metadata[descriptorId][MetadataField.P2P_CALC_RES] = calcedData
        metadata[descriptorId][MetadataField.P2P_CALC] = p2pCalc
    }

    static _hasDiffAboveThreshold(x, y, diffThreshold) {
        return Math.abs(((x / y) * 100) - 100) > diffThreshold;
    }

    /**
    * @description Retrieves the value of a given field.
    * The function tries to retrieve that data by iterating through the
    * options bellow until it find a value that is different than null,
    * undefined, empty, dash or the options are exhausted.
    *
    * 1) Direct field value
    * 2) P2P_CALC_RES of the field metadata
    * 3) DATA_DIFF_EXPECTATION of the field metadata.
    *
    * @param {Object} report Report to retrieve data from. Required
    * @param {string} descriptorId Descriptor ID of the field to retrieve data for. Required
    * @returns {[value:number | string | undefined,dataSource:string]}
    * Returns a tuple containing the value as first argument and the source
    * where the value was taken from as second argument. Both are undefined
    * if a value cannot not found.
    */
    static getDynamicFieldValue(report, descriptorId) {
        let value = report[ReportField.DYNAMIC_FIELDS][descriptorId]
        let dataSource = DATA_SOURCES.FIELD_VALUE
        const fieldMetadata = ReportUtils.getFieldMetadata(report, descriptorId);
        if (ObjCheck.isNullUndefinedEmptyOrDash(value) && fieldMetadata) {
            if (fieldMetadata[MetadataField.P2P_CALC_RES]) {
                value = fieldMetadata[MetadataField.P2P_CALC_RES];
                dataSource = DATA_SOURCES.P2P_CALC
            } else if (fieldMetadata[MetadataField.DATA_DIFF_EXPECTATION]) {
                value = fieldMetadata[MetadataField.DATA_DIFF_EXPECTATION]
                dataSource = DATA_SOURCES.DATA_DIFF
            }
        }

        return [value, dataSource]

    }
}


//TODO Export to utils
function isDash(data) {
    return data === "-"
}

function getNormalizedDataValue(data) {
    return ObjCheck.isNullUndefinedEmptyOrDash(data) ? "-" : data
}


export default ReportUtils