import { MetadataField, ReportField, SrcFieldValue } from '../../../../../../constnats/reportConstants';
import { Button, Modal, Form, Table, Header } from 'semantic-ui-react';
import ReportsRepo from '../../../../../../utils/repository/repoertsRepo';
import { useState } from 'react';
import Loader from '../../../../../../components/loader';
import ReportUtils from '../../../../../../utils/reportUtils';
import Utils from '../../../../../../utils/descriptor/descriptorUtils';
import { DescriptorProps } from '../../../../../../constnats/descriptor';
import ConfirmationModal from '../../../../../../components/confirmationModal';

const DIFF_METADATA_PROPS = [
    MetadataField.REPORTED_AS_LABEL, MetadataField.CALCS_DISABLED,
    MetadataField.MANUALLY_CALCED, MetadataField.INPUT_SCALE,
    MetadataField.NOTES, MetadataField.SRC
]

const DEFAULT_COPY_CFG = {
    [MetadataField.REPORTED_AS_LABEL]: true,
    [MetadataField.CALCS_DISABLED]: false,
    [MetadataField.MANUALLY_CALCED]: false,
    [MetadataField.INPUT_SCALE]: false,
    [MetadataField.NOTES]: false,
    [MetadataField.SRC]: false,
}


function updateReports(reports, onComlete) {
    function updateReport(reports, idx) {
        if (reports[idx] !== undefined) {
            ReportsRepo.update(reports[idx], res => updateReport(reports, ++idx))
        } else if (reports.length > 0) {
            onComlete()
        }
    }

    updateReport(reports, 0)
}


function prepareDescriptorsForUpdate(descriptors, configMap, srcFieldSpecificMetadata, targetReportNormalized) {

    for (const descriptor of descriptors) {
        const descriptorId = descriptor.id
        const srcMeta = srcFieldSpecificMetadata[descriptorId]
        const targetMeta = ReportUtils.getFieldMetadata(targetReportNormalized, descriptorId)

        for (const prop of Object.keys(configMap)) {
            if (configMap[prop]) {
                targetMeta[prop] = srcMeta[prop]
            }
        }
        const subFields = descriptor[DescriptorProps.SUBFIELDS]
        if (subFields?.length > 0) {
            prepareDescriptorsForUpdate(subFields, configMap, srcFieldSpecificMetadata, targetReportNormalized)
        }
    }
}

function prepareReportsForUpdate(srcReport, targetReports, descriptors,
    schemaId, configMap) {

    const srcReportCopy = JSON.parse(JSON.stringify(srcReport))
    const srcReportNormalized = ReportUtils.normalizeReport(srcReportCopy, descriptors)
    const srcFieldSpecificMetadata = ReportUtils.getFieldsMetadata(srcReportNormalized)

    const preparedReports = []
    for (const report of targetReports) {
        const targetReportCopy = JSON.parse(JSON.stringify(report))
        const oldTargetReportNormalized = ReportUtils.normalizeReport(targetReportCopy, descriptors)
        const newTargetReportNormalized = JSON.parse(JSON.stringify(oldTargetReportNormalized))

        prepareDescriptorsForUpdate(descriptors, configMap, srcFieldSpecificMetadata, newTargetReportNormalized)
        targetReportCopy.schemaId = schemaId
        preparedReports.push({ oldReport: oldTargetReportNormalized, newReport: newTargetReportNormalized })

    }

    return preparedReports
}

function getConflicts(preUpdateReport, postUpdateReport) {
    const conflicts = {}

    const defaultMetadata = ReportUtils.getDefaultMetadata()
    for (const descriptorId of Object.keys(ReportUtils.getFieldsMetadata(preUpdateReport))) {
        const oldMetadata = ReportUtils.getFieldMetadata(preUpdateReport, descriptorId)
        const newMetadata = ReportUtils.getFieldMetadata(postUpdateReport, descriptorId)

        for (const prop of DIFF_METADATA_PROPS) {

            if (oldMetadata[prop] !== newMetadata[prop] &&
                defaultMetadata[prop] !== oldMetadata[prop]) {
                if (conflicts[descriptorId] === undefined) {
                    conflicts[descriptorId] = []
                }

                conflicts[descriptorId].push(new PropDiff(prop, oldMetadata[prop], newMetadata[prop]))
            }
        }
    }

    return conflicts
}

function ConfigField({ configMap, property, label, onMapChange }) {
    return <Form.Checkbox checked={configMap[property]} label={label}
        onChange={(evt, data) => {
            const mapCopy = Object.assign({}, configMap)
            mapCopy[property] = data.checked
            onMapChange(mapCopy)
        }} />
}

function CopyDataModal({ schemaId, srcReport, copyTargets, descriptors, closeModal }) {
    const [loading, setLoading] = useState(false)
    const [availableTargets] = useState(copyTargets)
    const [selectedTargetIds, setSelectedTargetIds] = useState(availableTargets.map(r => r.id))
    const [configMap, setConfigMap] = useState(Object.assign({}, DEFAULT_COPY_CFG))
    const [confirmModal, setConfirmModal] = useState(<></>)

    const preparedReportsForUpdate = prepareReportsForUpdate(srcReport,
        availableTargets, descriptors, schemaId, configMap)

    function onSelectedTargetsChange(id, added) {
        const copy = selectedTargetIds.slice()
        if (added) {
            copy.push(id)
        } else {
            const index = copy.indexOf(id);
            if (index !== -1) {
                copy.splice(index, 1);
            }
        }

        setSelectedTargetIds(copy)
    }

    function handleCopy(reportsToUpdate) {
        setLoading(true)
        updateReports(reportsToUpdate,
            () => {
                setConfirmModal(<></>)
                setLoading(false)
                closeModal(true)
            })
    }

    return loading ? <Loader /> :
        <Modal size='large' open={true}>
            {confirmModal}
            <Modal.Header>Copy Report Data</Modal.Header>
            <Modal.Content >
                {availableTargets.length === 0 ?
                    <Header as="h4" textAlign='center'>No Corresponding Reports</Header> :
                    <>
                        <Header as="h5">Copy Configuration</Header>
                        <Form>
                            <ConfigField configMap={configMap}
                                property={MetadataField.REPORTED_AS_LABEL}
                                label="Copy Labels"
                                onMapChange={setConfigMap} />
                            <ConfigField configMap={configMap}
                                property={MetadataField.NOTES}
                                label="Copy Notes"
                                onMapChange={setConfigMap} />
                            <ConfigField configMap={configMap}
                                property={MetadataField.INPUT_SCALE}
                                label="Copy Scale"
                                onMapChange={setConfigMap} />
                            <ConfigField configMap={configMap}
                                property={MetadataField.MANUALLY_CALCED}
                                label="Copy Manually Calculated"
                                onMapChange={setConfigMap} />
                            <ConfigField configMap={configMap}
                                property={MetadataField.CALCS_DISABLED}
                                label="Copy Disable Automatic Calculatios"
                                onMapChange={setConfigMap} />
                            <ConfigField configMap={configMap}
                                property={MetadataField.SRC}
                                label="Copy Fields Source"
                                onMapChange={setConfigMap} />
                        </Form>
                    </>}
                <Preview {...{
                    selectedTargetIds, onSelectedTargetsChange,
                    preparedReportsForUpdate, descriptors
                }} />
            </Modal.Content>
            <Modal.Actions>
                <Button size='mini' primary onClick={() => {
                    const reportsToUpdate = preparedReportsForUpdate.map(p => p.newReport)
                        .filter(r => selectedTargetIds.includes(r.id))
                    const nonMixedReport = reportsToUpdate.find(report => report[ReportField.SRC] !== SrcFieldValue.MIXED)
                    if (configMap[MetadataField.SRC] && nonMixedReport) {
                        setConfirmModal(<ConfirmationModal msg='You are about to copy source fields from a Mixed to non Mixed report' onConfirm={() => handleCopy(reportsToUpdate)} onCancel={() => setConfirmModal(<></>)} />)
                    } else {
                        handleCopy(reportsToUpdate)
                    }

                }}>
                    Copy
                </Button>
                <Button size='mini' onClick={closeModal}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal >
}

function Preview({ selectedTargetIds, preparedReportsForUpdate,
    onSelectedTargetsChange, descriptors }) {

    const flatDescriptors = Utils.flatDescriptorsMap(descriptors)

    return preparedReportsForUpdate.length > 0 &&
        <>
            <Header as="h5">The following {selectedTargetIds.length} reports are going to be updated.</Header>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Year</Table.HeaderCell>
                        <Table.HeaderCell>Period</Table.HeaderCell>
                        <Table.HeaderCell>Filling Type</Table.HeaderCell>
                        <Table.HeaderCell>Conflicts</Table.HeaderCell>
                        <Table.HeaderCell>Copy To</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {preparedReportsForUpdate.map((p, idx) => {
                        const newReport = p.newReport

                        return <Table.Row key={idx} >
                            <Table.Cell>{newReport.year}</Table.Cell>
                            <Table.Cell>{newReport.period}</Table.Cell>
                            <Table.Cell>{newReport.type}</Table.Cell>
                            <Table.Cell>
                                <Diff flatDescriptors={flatDescriptors}
                                    preUpdateReport={p.oldReport}
                                    postUpdateReport={newReport} />
                            </Table.Cell>
                            <Table.Cell>
                                <Form.Checkbox checked={selectedTargetIds.includes(newReport.id)}
                                    onChange={(evt, data) =>
                                        onSelectedTargetsChange(newReport.id, data.checked)
                                    } />
                            </Table.Cell>
                        </Table.Row>
                    })}
                </Table.Body>
            </Table >
        </>
}

class PropDiff {
    constructor(prop, oldVal, newVal) {
        this.prop = prop
        this.oldVal = oldVal
        this.newVal = newVal
    }
}

function Diff({ preUpdateReport, postUpdateReport, flatDescriptors }) {
    const diff = getConflicts(preUpdateReport, postUpdateReport)

    return Object.keys(diff).length > 0 &&
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Field</Table.HeaderCell>
                    <Table.HeaderCell>Property</Table.HeaderCell>
                    <Table.HeaderCell>Old Value</Table.HeaderCell>
                    <Table.HeaderCell>New Value</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {Object.entries(diff).map(([k, v], idx) =>
                    v.map((d, idx2) =>
                        <Table.Row warning key={idx + "_" + idx2}>
                            <Table.Cell>{flatDescriptors[k].label}</Table.Cell>
                            <Table.Cell>{d.prop}</Table.Cell>
                            <Table.Cell>{String(d.oldVal === null ? false : d.oldVal)}</Table.Cell>
                            <Table.Cell>{String(d.newVal === null ? false : d.newVal)}</Table.Cell>
                        </Table.Row>)
                )}
            </Table.Body>
        </Table>
}


export default CopyDataModal