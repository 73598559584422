import { Dropdown, Form, Popup, Table } from "semantic-ui-react"
import TableUpdatesRepo from "../../../../utils/repository/tableUpdatesRepo";
import { useEffect, useMemo, useState } from "react";
import Loader from "../../../../components/loader";
import UserRepo from "../../../../utils/repository/userRepo";
import CollectionUtils from "../../../../utils/collections";
import { ROLES } from "../../../../constnats/user";
import AuthService from "../../../../service/auth";
import { adjustedUserGroupedMistakeKPIs, getRawUserGroupedMistakeKPIs } from "../utils/qualityUtils";
import useDateRange from "../../../../hooks/useDateRange";
import { subMonths } from "date-fns";
import RangeDatePicker from '../../../../components/rangeDatePicker'


function QualityKPIs({ usersMap, from, to }) {
    const [mistakes, setMistakes] = useState({ updates: [], reviews: [], qaEffectiveness:[] })
    const [tableTypeOptions, setTableTypeOptions] = useState([])
    const [userOptions, setUserTypeOptions] = useState([])
    const [tableTypes, setTableTypes] = useState([])
    const [users, setUsers] = useState([])

    useEffect(() => {
        TableUpdatesRepo.performanceKpisV2({
            from, to,
            includeDataWithUnknownMistakes: false
        }, (mistakeKpis) => {
            const tableTypes = new Set()

            mistakeKpis.updates.map(c => c.tableType)
                .forEach(t => tableTypes.add(t))
            mistakeKpis.reviews.map(c => c.tableType)
                .forEach(t => tableTypes.add(t))

            const tableTypesArr = Array.from(tableTypes)
            setTableTypeOptions(
                tableTypesArr.map(t => {
                    return { key: t, text: t, value: t }
                })
            )
            setUserTypeOptions(
                Object.values(usersMap).map(u => {
                    return { key: u.id, text: u.fullName, value: u.id }
                })
            )
            setUsers(Object.keys(usersMap).map(id => Number(id)))

            setTableTypes(tableTypesArr)
            setMistakes(mistakeKpis)
        })

    }, [from, to]);


    const currentUserId = AuthService.getUserId()
    const allowedUserIds = [2495, 2491, 157053, 997797]
    const privacyMode = !AuthService.hasGlobalRole(ROLES.ADMIN) && !allowedUserIds.includes(currentUserId)

    const userToMistakesKPIs = getRawUserGroupedMistakeKPIs(users, mistakes, tableTypes)
    const mistakeAjustedKPIs = adjustedUserGroupedMistakeKPIs(userToMistakesKPIs)

    return <>
        <br /><br />
        <Form.Field>
            <label>Table Types: </label>
            <Dropdown
                onChange={(a, b) => setTableTypes(b.value)}
                value={tableTypes}
                placeholder='Table Types'
                options={tableTypeOptions}
                multiple selection search />
        </Form.Field >
        <Form.Field>
            <label>Users: </label>
            <Dropdown
                onChange={(a, b) => setUsers(b.value)}
                value={users}
                placeholder='Users'
                options={userOptions}
                multiple selection search />
        </Form.Field >

        <Table striped selectable celled>
            <Table.Header>
                <Table.Row >
                    <Table.HeaderCell rowSpan='2'>User</Table.HeaderCell>
                    <Table.HeaderCell colSpan='7'>Mistake Points Per 100 Cells</Table.HeaderCell>
                </Table.Row>
                <Table.Row >
                    <Table.HeaderCell>Updates</Table.HeaderCell>
                    <Table.HeaderCell>Full Check Reviews</Table.HeaderCell>
                    <Table.HeaderCell>Core Data Reviews (Mistake Detection)</Table.HeaderCell>
                    <Table.HeaderCell>Core Data Reviews (Regular Update)</Table.HeaderCell>
                    <Table.HeaderCell>Core Data Reviews (Total)</Table.HeaderCell>
                    <Table.HeaderCell>Detected Mistakes as QA</Table.HeaderCell>
                    <Table.HeaderCell>Total exc. Core Data Reviews</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {mistakeAjustedKPIs
                    .filter(a => a.totalScore !== undefined || a.coreDataReviewsScore !== undefined)
                    .sort((a, b) => a.totalScore - b.totalScore)
                    .map(({
                        userId, updatesScore, updatesScope, fullReviewsScore,
                        fullReviewsScope, coreDataReviewsScore, totalScore, totalScope,
                        coreDataReviewsScope, incrementalCoreDataReviewsScore, incrementalCoreDataReviewsScope,
                        totalCoreDataReviewsScore, totalCoreDataReviewsScope,
                        detectedMistakesAsQAScore, detectedMistakesAsQAScope
                    }) =>
                        <Table.Row key={userId}>
                            <Table.Cell>
                                {(privacyMode && userId != currentUserId) ? "" : (usersMap[userId]?.fullName || userId)}
                            </Table.Cell>
                            <Table.Cell>
                                <QualityScore score={updatesScore} scope={updatesScope} />
                            </Table.Cell>
                            <Table.Cell >
                                <QualityScore score={fullReviewsScore} scope={fullReviewsScope} />
                            </Table.Cell>
                            <Table.Cell >
                                <QualityScore score={coreDataReviewsScore} scope={coreDataReviewsScope} />
                            </Table.Cell>
                            <Table.Cell >
                                <QualityScore score={incrementalCoreDataReviewsScore} scope={incrementalCoreDataReviewsScope} />
                            </Table.Cell>
                            <Table.Cell>
                                <QualityScore score={totalCoreDataReviewsScore} scope={totalCoreDataReviewsScope}/>
                            </Table.Cell>
                            <Table.Cell >
                                <QualityScore score={detectedMistakesAsQAScore} scope={detectedMistakesAsQAScope} />
                            </Table.Cell>
                            <Table.Cell>
                                <QualityScore score={totalScore} scope={totalScope} />
                            </Table.Cell>
                        </Table.Row>
                    )
                }
            </Table.Body>
        </Table>
    </>
}

function QualityScore({ score, scope }) {
    score = score === undefined ? "" : Number(score).toFixed(2)
    return <Popup
        size='tiny'
        content={<>Cells Scope:{scope}</>}
        trigger={<div>{score === undefined ? "" : Number(score).toFixed(2)}</div>}
    />
}


function QualityMetrix() {
    const [usersMap, setUsersMap] = useState(null)
    const { from, to, handleFromChange, handleToChange, handleMoveToNextMonth, handleMoveToPrevMonth } = useDateRange({ from: subMonths(new Date(), 1), to: new Date() })

    const fromTM = useMemo(() => new Date(from.getTime()).setUTCHours(0, 0, 0, 0), [from])
    const toTM = useMemo(() => new Date(to.getTime()).setUTCHours(23, 59, 0, 0), [to])

    useEffect(() => {
        UserRepo.list({ userRoles: [ROLES.EMPLOYEE], includeDisabled: false },
            users => setUsersMap(CollectionUtils.arrToMap(users, u => u.id)))
    }, []);

    return (
        usersMap === null ? <Loader /> :
            <>
                <p>NOTE: Dates before 11 Mar. 2024 are not supported</p>
                <RangeDatePicker from={from} to={to}
                    handleFromChange={handleFromChange} handleToChange={handleToChange}
                    handleMoveToNextMonth={handleMoveToNextMonth} handleMoveToPrevMonth={handleMoveToPrevMonth} />
                <QualityKPIs {...{ usersMap, from: fromTM, to: toTM }} />
            </>
    )
}


export default QualityMetrix