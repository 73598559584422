import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Header, Icon, Modal, TextArea } from 'semantic-ui-react'
import Loader from '../../loader';
import { DescriptorPair, SchemaDiffUtil } from '../../../utils/schemaDiffUtil';
import { Diff, ReportDiffUtil } from '../../../utils/reportDiffUtil';
import ReviewViewService from '../../../utils/repository/ui/reviewViewService';
import ShemaDiff from './schemaDiff'
import ReportsDiff from './reportsDiff'
import AuthService from '../../../service/auth';
import { ROLES } from '../../../constnats/user';
import ConfirmationModal from '../../confirmationModal';
import { AuthContext } from '../../../App';
import Utils from '../../../utils/descriptor/descriptorUtils';
import TableUpdatesRepo from '../../../utils/repository/tableUpdatesRepo';
import UserRepo from '../../../utils/repository/userRepo';
import { serverToLocalDateTime } from '../../../utils/dateTimeUtils';
import { ReportField } from '../../../constnats/reportConstants';
import { ReportsUtils } from '../../../utils/reportsUtils';
import { ReviewType } from '../../financialTables/configuration/constants';
import DuplicateReportError from '../../../customErrors/duplicateReportsError';

class ReviewStatus {
    static REJECTED = "REJECTED"
    static APPROVED = "APPROVED"
    static IN_PROGRESS = "IN_PROGRESS"
    static APPROVAL_AUTO_REJECTED = "APPROVAL_AUTO_REJECTED"
    static PENDING_REVISION = "PENDING_REVISION"
}

class ReviewPhase {
    static TABLE_DATA_REMEDIATION = "TABLE_DATA_REMEDIATION"
    static MODEL_REMEDIATION = "MODEL_REMEDIATION"
    static DRAFT_FILLING = "DRAFT_FILLING"
}

class ReviewTab extends React.Component {

    static deleteConfirmationMsg = "Are you sure that you want to delete the review? This operation will also delete all the review model and reports associated to it."

    constructor(props) {
        super(props);
        this.state = {
            review: null,
            schemaDiffs: [],
            reportDiffs: [],
            srcToEditDiff: null,
            hasSchemaErrors: true,
            hasDiffErrors: false,
            modal: <></>,
            detailedSchemaDiff: false,
            detailedReportsDiff: false,
            nextPhase: null,
            loading: true
        }

        this.editSchema = null
        this.reviewSchema = null
        this.editReports = null
        this.reviewReports = null
    }

    componentDidMount() {
        //TODO too much duplication 
        this.refresh()
    }

    refresh() {
        //TODO too much duplication 
        ReviewViewService.getReviewViewData(this.props.reviewId, viewData => {
            const srcToEditDiff = <SrcToEditDiff
                editSchema={viewData.editSchema}
                srcSchema={viewData.srcSchema}
                editReports={viewData.editReports}
                srcReports={viewData.srcReports}
                companyId={viewData.update.companyId} />

            const review = viewData.review
            const phase = review.phase

            const diffCfg = {
                detailedSchemaDiff: phase === ReviewPhase.TABLE_DATA_REMEDIATION
                    || phase === ReviewPhase.MODEL_REMEDIATION,
                detailedReportsDiff: phase === ReviewPhase.TABLE_DATA_REMEDIATION
            }

            let nextPhase = null
            if (phase === ReviewPhase.DRAFT_FILLING) {
                nextPhase = ReviewPhase.MODEL_REMEDIATION
            } else if (review.phase === ReviewPhase.MODEL_REMEDIATION) {
                nextPhase = ReviewPhase.TABLE_DATA_REMEDIATION
            }

            const coreDataDiffOnly = review.type === ReviewType.CORE_DATA_CHECK
            this.setState({
                loading: false, review, srcToEditDiff, ...diffCfg,
                nextPhase, coreDataDiffOnly, update: viewData.update
            })

            if (viewData.reviewReports) {
                this.checkReviewToEditDiff(viewData.editSchema, viewData.reviewSchema,
                    viewData.editReports, viewData.reviewReports)
            }
        })
    }

    closeModal() {
        this.setState({ modal: <></> })
    }

    openModal(modal) {
        this.setState({ modal })
    }

    checkReviewToEditDiff(editSchema, reviewSchema, editReports, reviewReports) {
        const coreDataDiffOnly = this.state.coreDataDiffOnly
        const diffRes = SchemaDiffUtil.schemaDiff(editSchema, reviewSchema, coreDataDiffOnly)
        const schemaDiffs = diffRes.diffs
        this.setState({ schemaDiffs: schemaDiffs })
        const hasSchemaErrors = schemaDiffs.findIndex(d => d.type === "ERROR") >= 0
        let descriptorPairs = diffRes.descriptorPairs
        try {
            const reportDiffs = ReportDiffUtil.reportsDiff(editReports, reviewReports, descriptorPairs, coreDataDiffOnly)
            this.setState({
                reportDiffs: reportDiffs,
                hasSchemaErrors: false,
                hasDiffErrors: hasSchemaErrors || reportDiffs.length > 0
            })
        } catch (error) {
            console.log(error)
            const hasDiffErrors = error instanceof DuplicateReportError ? true : false
            this.setState({ hasSchemaErrors, hasDiffErrors})
        }
    }

    updateStatus(status) {
        this.update({ status })
    }

    updatePhase(phase) {
        this.openModal(<ConfirmationModal
            msg={"Are you sure that you want to proceed to " + phase + " phase?"}
            onConfirm={() => {
                this.update({ phase })
                this.closeModal()
            }}
            onCancel={() => this.closeModal()} />)
    }

    transferOwnership() {
        this.openModal(<TransferOwnershipModal ownerId={this.state.review.ownerId}
            onSave={newOwnerId => {
                this.update({ ownerId: newOwnerId })
                this.closeModal()
            }}
            onCancel={() => this.closeModal()} />)
    }

    setNotes() {
        this.openModal(<SetNotesModal notes={this.state.review.notes}
            onSave={newNotes => {
                this.update({ notes: newNotes })
                this.closeModal()
            }}
            onCancel={() => this.closeModal()} />)
    }

    update(updatedProps) {
        this.setState({ loading: true })
        TableUpdatesRepo.getReview(this.state.review.id, review => {
            let currentReview = {
                reviewId: review.id,
                status: review.status,
                phase: review.phase,
                ownerId: review.ownerId,
                notes: review.notes
            }
            TableUpdatesRepo.updateReview(
                Object.assign(currentReview, updatedProps)
                , review => {
                    this.refresh()
                    this.setState({ loading: false })
                    //TODO handle startus change specifically
                    this.props.onReviewStatusChange()
                })
        })
    }

    delete() {
        this.openModal(<ConfirmationModal
            msg={ReviewTab.deleteConfirmationMsg}
            onConfirm={() => TableUpdatesRepo.deleteReview(this.props.reviewId, () => {
                this.props.onDeleted()
            })}
            onCancel={() => this.closeModal()} />)
    }

    actions() {
        const review = this.state.review
        const reviewStatus = review.status
        const fastTrackReviewer = AuthService.hasRole(this.context, ROLES.DO_FAST_TRACK_REVIEWS)
        const getChangeReviewStatusBtn = (newStatus) =>
            <Button key={newStatus} onClick={() => this.updateStatus(newStatus)} size='mini' primary>Change status to {newStatus}</Button>
        const buttons = []

        const transferOwnershipBtn = <Button key="transferOwnership" size='mini'
            onClick={() => this.transferOwnership()}>
            Transfer Ownership
        </Button>

        if (review.ownerId === AuthService.getUserId()) {
            if (reviewStatus !== ReviewStatus.REJECTED) {
                buttons.push(getChangeReviewStatusBtn(ReviewStatus.REJECTED))
            }

            if (this.state.nextPhase) {
                buttons.push(
                    <Button key="enableDiffCheck" size='mini' primary
                        onClick={() => this.updatePhase(this.state.nextPhase)}>
                        Start {this.state.nextPhase}
                    </Button>)
            } else {
                if (reviewStatus !== ReviewStatus.IN_PROGRESS) {
                    buttons.push(getChangeReviewStatusBtn(ReviewStatus.IN_PROGRESS))
                } else {
                    if (review.type === "VISUAL_CHECK") {
                        buttons.push(getChangeReviewStatusBtn(ReviewStatus.APPROVED))
                    } else {
                        if ((fastTrackReviewer || !this.state.hasDiffErrors)
                            && reviewStatus !== ReviewStatus.APPROVED) {
                            buttons.push(getChangeReviewStatusBtn(ReviewStatus.APPROVED))
                        }
                    }
                }
            }

            buttons.push(
                <Button key="setNotes" size='mini'
                    onClick={() => this.setNotes()}>
                    Set Notes
                </Button>)

            buttons.push(transferOwnershipBtn)
        }


        if (AuthService.hasAnyGlobalRole([ROLES.REVIEWS_MANAGER])) {
            buttons.push(
                <Button key="delete" onClick={() => this.delete()} size='mini' color='red'>
                    Delete
                </Button>)
            if (!buttons.includes(transferOwnershipBtn)) {
                buttons.push(transferOwnershipBtn)
            }
        }

        return buttons
    }

    content() {
        const state = this.state
        const review = this.state.review
        const schemaDiffs = state.schemaDiffs
        const reportDiffs = state.reportDiffs
        const update = state.update
        const reportsForDeferredCheck = update.reportsForDeferredCheck

        const visualCheck = review.type === "VISUAL_CHECK"
        return <div>
            <Icon color='grey' size='large' name='refresh'
                className='cursorPointer floatRight'
                onClick={() => this.refresh()} />
            {state.modal}
            <b>Status:</b> {review.status}<br />
            <b>Phase:</b> {review.phase}<br />
            <b>Type:</b> {review.type}<br />
            <b>Created On:</b> {serverToLocalDateTime(review.createdOn)}<br />
            <b>Updaeted On:</b> {serverToLocalDateTime(review.updatedOn)}<br />
            {reportsForDeferredCheck && <><b>Reports for correctness check:</b> {reportsForDeferredCheck.join(" ,")}<br /></>}
            <b>Update Description:</b> {<div dangerouslySetInnerHTML={{ __html: update.description.replace(/(?:\r\n|\r|\n)/g, '</br>') }}></div>}<br />
            {review.notes !== null && <><b>Notes:</b> {review.notes}</>}


            {!visualCheck && <ReiewToEditDiff {...{ schemaDiffs, reportDiffs }}
                detailedSchemaDiff={state.detailedSchemaDiff}
                detailedReportsDiff={state.detailedReportsDiff}
                hasSchemaErrors={state.hasSchemaErrors}
                companyId={update.companyId} />}


            {state.detailedReportsDiff && state.srcToEditDiff}
            <br />
            {this.actions()}
        </div>
    }

    render() {
        return this.state.loading ? <Loader /> : this.content()
    }
}

function ReiewToEditDiff({ schemaDiffs, reportDiffs, detailedSchemaDiff,
    detailedReportsDiff, hasSchemaErrors, companyId }) {
    return <>

        {detailedSchemaDiff ? <ShemaDiff {...{ schemaDiffs }} /> :
            <Header as="h5">Model Differences:  {schemaDiffs.length}</Header>}
        {detailedSchemaDiff && <ReportsDiff
            schemaErrors={hasSchemaErrors}
            reportDiffs={reportDiffs}
            editName="EDIT"
            reviewName="REVIEW"
            companyId={companyId}
            detailed={detailedReportsDiff} />}
    </>
}

function reportKey(report) {
    return report.year + report.period + report.reportType + report.type
}

function SrcToEditDiff({ editSchema, srcSchema, editReports,
    srcReports, companyId }) {

    const srcReportKeys = srcReports.map(reportKey)

    const srcDescriptorPairs = []
    const flatSrcDescriptors = Utils.flatDescriptors(srcSchema.descriptors)

    for (const descriptor of flatSrcDescriptors) {
        srcDescriptorPairs.push(new DescriptorPair(descriptor, descriptor))
    }

    const addedFields = []
    const flatEditDescriptors = Utils.flatDescriptors(editSchema.descriptors)
    const addedDescriptorPairs = []
    for (const descriptor of flatEditDescriptors) {
        if (flatSrcDescriptors.findIndex(d => descriptor.id === d.id) < 0) {
            addedFields.push(descriptor)
            addedDescriptorPairs.push(new DescriptorPair(descriptor, descriptor))
        }
    }

    const removedFields = []
    for (const descriptor of flatSrcDescriptors) {
        if (flatEditDescriptors.findIndex(d => descriptor.id === d.id) < 0) {
            removedFields.push(descriptor)
        }
    }

    const editReportsWithSrc = []
    const editReportsWithoutSrc = []
    for (const report of editReports) {
        if (srcReportKeys.includes(reportKey(report))) {
            editReportsWithSrc.push(report)
        } else {
            editReportsWithoutSrc.push(report)
        }
    }

    /**
     * Covers the intersection between src reports and src descriptor pairs.
     */
    const srcReportsDiff = ReportDiffUtil
        .reportsDiff(editReportsWithSrc, srcReports, srcDescriptorPairs, false, true)

    /**
     * Covers the intersection between src reports and added descriptor pairs.
     */
    const addedReportsDiff = ReportDiffUtil
        .reportsDiff(editReportsWithSrc, srcReports, addedDescriptorPairs, false, true)
        .filter(d => d.fieldLabel !== "N/A")
    addedReportsDiff.forEach(d => d.type = Diff.WARNING)
    srcReportsDiff.push(...addedReportsDiff)

    const labelRenamings = []
    for (const srcDescriptor of flatSrcDescriptors) {
        if (removedFields.findIndex(d => d.id === srcDescriptor.id) < 0) {
            const editDescriptor = flatEditDescriptors.find(d => d.id === srcDescriptor.id)
            if (editDescriptor && (editDescriptor.label != srcDescriptor.label)) {
                labelRenamings.push(srcDescriptor.label + " => " + editDescriptor.label)
            }
        }
    }

    return <>
        <Header as="h5">MAIN Branch Comparison</Header>
        <b>Renamed Fields</b>
        {labelRenamings.length > 0 ?
            <ul>
                {labelRenamings.map((d, i) => <li key={i}>{d}</li>)}
            </ul> : <><br />No Renamed Fields</>}

        <br />
        <b>Added Fields</b>
        {addedFields.length > 0 ?
            <ul>
                {addedFields.map(d => <li key={d.id}>{d.label}</li>)}
            </ul> : <><br />No Added Fields</>}
        <br />
        <b>Removed Fields</b>
        {removedFields.length > 0 ?
            <ul>
                {removedFields.map(d => <li key={d.id}>{d.label}</li>)}
            </ul> :
            <><br />No Removed Fields</>
        }
        <br />
        <b>Added Reports</b>
        {editReportsWithoutSrc.length > 0 ?
            <ul>
                {ReportsUtils.sort(editReportsWithoutSrc).map(r => <li key={r.id}>{getReportName(r)}</li>)}
            </ul> :
            <><br />No Added Reports</>
        }
        <ReportsDiff
            schemaErrors={false}
            reportDiffs={srcReportsDiff}
            editName="EDIT"
            reviewName="MAIN"
            detailed={true}
            companyId={companyId} />
    </>
}

ReviewTab.contextType = AuthContext;

function getReportName(report) {
    return report[ReportField.YEAR] + "/" + report[ReportField.PERIOD] +
        "/" + report[ReportField.TYPE] + "/" + report[ReportField.REPORT_TYPE]
}

function TransferOwnershipModal({ ownerId, onSave, onCancel }) {
    let [users, setUsers] = useState([])
    let [_ownerId, setOwnerId] = useState(ownerId)

    useEffect(() => {
        UserRepo.list(
            { userRoles: [ROLES.EMPLOYEE], includeDisabled: false },
            users =>
                setUsers(
                    users.map(r => {
                        return {
                            key: r.id,
                            text: r.fullName,
                            value: r.id
                        }
                    }))
        )
    })

    function save() {
        onSave(_ownerId)
    }

    return <Modal size="mini" open={true}>
        <Modal.Header>Transfer Ownership</Modal.Header>
        <Modal.Content>
            <Form>
                <Form.Field>
                    <label>Owner</label>
                    <Dropdown
                        onChange={(a, b) => setOwnerId(b.value)}
                        value={_ownerId}
                        placeholder="Owner"
                        options={users}
                        fluid selection search />
                </Form.Field>
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button size='mini' positive onClick={save}>
                Transfer
            </Button>
            <Button size='mini' negative onClick={onCancel}>
                Cancel
            </Button>
        </Modal.Actions>
    </Modal >
}

const INVALID_NOTES_MSG = 'The notes must not be more than 200 characters.'

function SetNotesModal({ notes, onSave, onCancel }) {
    const [_notes, setNotes] = useState(notes === null ? "" : notes)
    const [error, setError] = useState(false)

    function onNotesChange(notes) {
        if (notes.length > 200) {
            setError({ content: INVALID_NOTES_MSG })
        } else {
            setError(false)
        }
        setNotes(notes)
    }

    function save() {
        if (error === false) {
            onSave(_notes)
        }
    }

    return <Modal size="mini" open={true}>
        <Modal.Header>Notes</Modal.Header>
        <Modal.Content>
            <Form>
                <Form.Field
                    error={error}
                    control={TextArea}
                    value={_notes} onChange={v =>
                        onNotesChange(v.target.value)
                    } />
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button size='mini' positive onClick={(save)}>
                Save
            </Button>
            <Button size='mini' negative onClick={onCancel}>
                Cancel
            </Button>
        </Modal.Actions>
    </Modal >
}

export default ReviewTab